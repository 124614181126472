<template>
  <div>
    <form-wizard
      :color="is_loading.submit ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="is_loading.submit ? 'Updating...' : 'Update'"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account details tab -->
      <tab-content
        title="OKR Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="okr-form" class="p-2">
            <b-row>
              <b-col md="6">
                <b-form-group label="OKR Period Range" label-for="period_range">
                  <!-- v-show="data_local.leave_type_id != 6" -->
                  <flat-pickr
                    ref="period_range"
                    v-model="data_local.period_range"
                    :config="dateConfig"
                    class="form-control"
                    input-id="period_range"
                    placeholder="Select Start and End Date"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <b-button
                    class="mt-2"
                    style="width: 50%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="add()"
                  >
                    Add Objective
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <div
                  v-for="(inputs, k) in data_local.okr_details"
                  :key="k"
                  style="
                    border: 2px solid #38b22d;
                    padding: 15px;
                    border-radius: 50px 20px;
                  "
                  class="mt-1"
                >
                  <h5
                    style="
                      margin-top: -1.5em;
                      margin-left: 46%;
                      background-color: white;
                      display: block;
                      width: 100px;
                      text-align: center;
                    "
                  >
                    Objective {{ k + 1 }}
                  </h5>
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Type" label-for="objective_type">
                        <v-select
                          v-model="data_local.okr_details[k].type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="typeList"
                          :reduce="(label) => label.code"
                          label="label"
                          :clearable="false"
                          :searchable="false"
                          input-id="objective_type"
                          name="objective_type"
                          placeholder="Select OKR Type"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Status" label-for="status">
                        <v-select
                          v-model="data_local.okr_details[k].status"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusList"
                          :reduce="(label) => label.code"
                          label="label"
                          :clearable="false"
                          :searchable="false"
                          input-id="status"
                          name="status"
                          placeholder="Select Status"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-row>
                        <!-- <b-col cols="6">
                          <b-form-group>
                            <b-button class="mt-2" style="width:100%;"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                @click="copy(k)"
                              >
                              Copy 
                            </b-button>
                          </b-form-group>
                        </b-col> -->
                        <b-col cols="12">
                          <b-form-group>
                            <b-button
                              class="mt-2"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="danger"
                              @click="remove(k)"
                              v-if="data_local.okr_details.length > 1"
                            >
                              Delete
                            </b-button>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Objective" label-for="objective">
                        <b-form-textarea
                          id="objective"
                          v-model="data_local.okr_details[k].objective"
                          placeholder="Enter the Objective in Short"
                          rows="2"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Key Result" label-for="key_result">
                        <b-form-textarea
                          id="key_result"
                          v-model="data_local.okr_details[k].key_result"
                          placeholder="Enter the Key Result in Short"
                          rows="2"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Objective Notes" label-for="notes">
                        <b-form-textarea
                          id="notes"
                          v-model="data_local.okr_details[k].notes"
                          placeholder="Enter the Objective Note for Month-End Evaluation"
                          rows="2"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="6">
                      <b-form-group
                        label="Objective Description"
                        label-for="objective_description"
                      >
                      <b-form-textarea
                        id="objective_description"
                        v-model="data_local.okr_details[k].objective_description"
                        placeholder="Enter the Objective Description in Short"
                        rows="2"
                      />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Key Result Description"
                        label-for="key_result_description"
                      >
                      <b-form-textarea
                        id="key_result_description"
                        v-model="data_local.okr_details[k].key_result_description"
                        placeholder="Enter the Key Result Description in Short"
                        rows="2"
                      />
                      </b-form-group>
                    </b-col> -->
                  </b-row>
                </div>
              </b-col>

              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group v-if="item.type == 'number'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'text'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'url'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'rating'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group v-if="item.type == 'password'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group v-if="item.type == 'textarea'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group v-if="item.type == 'date'">
                    <label class="" :for="item.name">{{ item.label }}</label>
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import okrStoreModule from "../okrStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import VueCookies from "vue-cookies";
import { D } from "caniuse-lite/data/agents";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectOkr: "",
      data_local: {
        okr_details: [
          {
            type: "",
            objective: "",
            objective_description: "",
            key_result: "",
            key_result_description: "",
            status: "",
            notes: "",
          },
        ],
        period_range: "",
        get_customfields_data: [],
      },
      dateConfig: {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        //inline: true,
        mode: "range",
      },
      typeList: [
        {
          label: "Objective",
          code: "Objective",
        },
        {
          label: "Experiment",
          code: "Experiment",
        },
      ],
      statusList: [
        {
          label: "Not Started Yet",
          code: 0,
        },
        {
          label: "In Progress",
          code: 1,
        },
        {
          label: "Completed",
          code: 2,
        },
        {
          label: "On Hold",
          code: 3,
        },
        {
          label: "Cancelled",
          code: 4,
        },
      ],
      customFields: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-okrs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, okrStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.fetch_user_data(this.$route.params.id);
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    fetch_user_data(userId) {
      this.$store
        .dispatch("app-okrs/fetchOkr", userId)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
          // if (err.response.status === 404) {
          //   return;
          // }
          console.error(err);
        });
    },
    formSubmitted() {
      const self = this;
      var validData = this.checkData(self.data_local);
      if (validData.status) {
        const formData = new FormData(document.getElementById("okr-form"));
        formData.append(
          "okr_details",
          JSON.stringify(self.data_local.okr_details)
        );
        formData.append("period_range", self.data_local.period_range);
        formData.append("_method", "PATCH");
        var data = {
          okr_details: JSON.stringify(self.data_local.okr_details),
          period_range: self.data_local.period_range,
        };
        this.is_loading.submit = true;
        data._method = "PATCH";
        this.$store
          .dispatch("app-okrs/updateOkr", {
            id: self.$route.params.id,
            data: data,
          })
          .then((res) => {
            this.is_loading.submit = false;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.$router.push("/okrs");
          })
          .catch((error) => {
            this.is_loading.submit = false;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! OKR updating Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: error,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! OKR updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });
      } else {
        for (var key in validData.messages) {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: validData.messages[key].title,
          //     text: validData.messages[key].text,
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: validData.messages[key].title,
                icon: 'BellIcon',
                variant: 'danger',
                text: validData.messages[key].text,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      }
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-okrs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    add() {
      this.data_local.okr_details.push({
        type: "",
        objective: "",
        objective_description: "",
        key_result: "",
        key_result_description: "",
        status: "",
        notes: "",
      });
      console.log(this.data_local.okr_details);
    },
    remove(index) {
      this.data_local.okr_details.splice(index, 1);
    },
    copy(key) {
      this.data_local.okr_details.push(this.data_local.okr_details[key]);
      console.log(this.data_local.okr_details);
    },
    checkData(data) {
      var messages = [];

      var selectedDate = data.period_range;
      if (!selectedDate) return;
      var dates = selectedDate.split(" to ");
      if (dates.length != 2) {
        messages.push({
          title: "Oops! We need a Date range for OKR",
          text: "Please select Period Start and End Date",
        });
      }
      data.okr_details.forEach(function (obj, key) {
        key = key + 1;
        if (obj.type == "" || obj.type == null || obj.type == undefined) {
          messages.push({
            title: "Type is Required for Objective " + key,
            text: "Please Select Type for Objective " + key,
          });
        }
        if (
          obj.status != 0 &&
          (obj.status == "" || obj.status == null || obj.status == undefined)
        ) {
          messages.push({
            title: "Satus is Required for Objective " + key,
            text: "Please Select Status for Objective " + key,
          });
        }
        if (
          obj.objective == "" ||
          obj.objective == null ||
          obj.objective == undefined
        ) {
          messages.push({
            title: "Objective Title is Required for Objective " + key,
            text: "Please Select objective title for Objective " + key,
          });
        }
        if (
          obj.key_result == "" ||
          obj.key_result == null ||
          obj.key_result == undefined
        ) {
          messages.push({
            title: "key Result is Required for Objective " + key,
            text: "Please Select Key Result for Objective " + key,
          });
        }
      });

      var response = { status: false, messages: messages };
      if (messages.length == 0) {
        var response = { status: true, messages: messages };
      }
      return response;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
